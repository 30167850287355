import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import BreadcrumbSection from '../../components/breadcrumb'

const Security = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Security'
     const breadcrumbItems = [
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <SEO classBody="status-update" canonical={canonical} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <section className="platform-intro">
                    <div className="container">
                         <div className="platform-intro-title">
                              <h1>Security</h1>

                              <p>
                                   We are committed to building your trust, that's why we invest in security or our software and your data. Learn more
                                   about our commitment below.
                              </p>
                         </div>
                         <div className="platform-intro-content">
                              <h3>Security Measures</h3>
                              <p>
                                   ListenLayer commits to maintaining robust and suitable organizational and technical security measures. These
                                   measures encompass various aspects including personnel, facilities, hardware, software, data storage, network
                                   systems, access controls, along with monitoring and logging practices. Additionally, ListenLayer implements
                                   industry standard vulnerability and breach detection, incident response strategies, and encryption technologies.
                                   The primary goal of these measures is to safeguard against unauthorized or accidental access, loss, modification,
                                   disclosure, or destruction of systems and data, and to protect the rights of the individuals represented our data.
                              </p>
                              <h3>Compliance & Accreditation</h3>
                              <p>
                                   ListenLayer is in the process of completing SOC2 Type 2 and HIPAA compliance. We will update this page once our
                                   accreditations are complete.
                              </p>
                              <h3>Reviews and Auditing</h3>
                              <p>
                                   As part of our accreditation, and within our standard security policies, our systems undergo 3rd party and internal
                                   audits, scans, and penetration testing to reduce the likelihood of incidents.
                              </p>
                              <h3>Customer Requirements</h3>
                              <p>
                                   As a user of our platform, we ask you to act repsonsibly with the data you direct us to collect, the settings you
                                   implement using our software, and the security measures you have in place for access. To learn more about our
                                   commitment and yours, review our Terms of Service, Privacy Policy, and DPA which are all accessible from{' '}
                                   <a href="/terms-and-policies/" target="_blank" rel="noreferrer">
                                        this location.
                                   </a>
                              </p>
                              <h3>Issues or Questions</h3>
                              <p>If you identify issues or have questions, please email us at support@listenlayer.com.</p>
                         </div>
                    </div>
               </section>
          </Layout>
     )
}

export default Security
